import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import AccountForm from "../../components/Account/AccountForm.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Header/Header.jsx";
import Loading2 from "../../components/Loading/Loading2.jsx";
import LookupsContext from "../../context/LookupsContext";
import UserContext from "../../context/UserContext";
import { useForm } from "../../hooks/useForm.jsx";
import "./Account.scss";

const Account = ({ component, setUser }) => {
  const user = useContext(UserContext);
  const lookups = useContext(LookupsContext);
  console.log(lookups);
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState(null);

  const accountClass = async (values) => {
    if (values.email) values.email = values.email.toLowerCase();
    if (values.displayName && values.displayName.length < 4) {
      setUnsubmitted();
      setAlertMessage({
        type: "danger",
        message: "Displayed name must be empty or at least 4 characters",
      });
    } else {
      await axios
        .put(`/account/${user._id}`, {
          ...values,
        })
        .then((response) => {
          if (response.data.success) {
            setAlertMessage({
              type: "success",
              message: "Account updated successfully !",
            });
            setUser((u) => {
              return { ...u, ...values };
            });
            setTimeout(() => {
              navigate(`/cities/`);
            }, 1000);
          } else {
            setUnsubmitted();
            setAlertMessage({
              type: "danger",
              message: "Account has not been updated !",
            });
          }
        });
    }
  };

  const { entryData, error, onChange, onSubmit, setUnsubmitted } =
    useForm(accountClass);

  return (
    <div>
      <Header component={component} />
      {typeof lookups !== "object" && <Loading2 />}
      {typeof lookups === "object" && (
        <div className="editaccount-container">
          <AccountForm
            user={user}
            cities={lookups.cities}
            entryData={entryData}
            alertMessage={alertMessage}
            error={error}
            _onChange={onChange}
            _onSubmit={onSubmit}
          />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Account;
