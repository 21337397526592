import axios from "axios";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import CourseForm from "../../components/CourseForm/CourseForm.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Header/Header.jsx";
import { useForm } from "../../hooks/useForm.jsx";

import "./NewCoursePage.scss";

const NewCoursePage = ({ city, component }) => {
  const [alertMessage, setAlertMessage] = useState(null);
  const navigate = useNavigate();
  dayjs.extend(isBetween);

  const newCourse = async (values) => {
    if (dayjs(values.startDate).isAfter(dayjs(values.endDate))) {
      setUnsubmitted();
      setAlertMessage({
        type: "danger",
        message: "End Date must be after Start Date!",
      });
    } else if (dayjs(values.endDate) <= dayjs(new Date())) {
      setUnsubmitted();
      setAlertMessage({
        type: "danger",
        message: "End Date must be in future!",
      });
    } else {
      try {
        values.city = city;
        await axios
          .post("/courses", {
            ...values,
          })
          .then((response) => {
            if (response.data.success) {
              setAlertMessage({
                type: "success",
                message: "New Course added successfully !",
              });
              setTimeout(() => {
                if (city) {
                  navigate(`/${city}/courses/`);
                } else {
                  navigate(`/cities/`);
                }
              }, 1000);
            } else {
              setUnsubmitted();
              setAlertMessage({
                type: "danger",
                message: "New Course not added !",
              });
            }
          })
          .catch((err) => {
            if (!err.response.data.success) {
              setAlertMessage({
                type: "danger",
                message: err.response.data.message,
              });
            }
          });
      } catch (err) {
        setUnsubmitted();
        setAlertMessage({
          type: "danger",
          message: "New Course not added !",
        });
      }
    }
  };
  const { entryData, error, onChange, onSubmit, setUnsubmitted } =
    useForm(newCourse);

  return (
    <div>
      <Header city={city} component={component} />
      <div className="newcourse-container">
        <CourseForm
          city={city}
          component={component}
          alertMessage={alertMessage}
          entryData={entryData}
          error={error}
          _onChange={onChange}
          _onSubmit={onSubmit}
        />
      </div>
      <Footer />
    </div>
  );
};

export default NewCoursePage;
