import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Header/Header.jsx";
import LookupsContext from "../../context/LookupsContext";

import "./Cities.scss";

const Cities = ({ component }) => {
  const lookups = useContext(LookupsContext);
  const [searchResult, setSearchResult] = useState(
    lookups ? lookups.cities : []
  );
  const search = (e) => {
    const searchText = e.target.value.toLowerCase();
    setSearchResult(
      lookups.cities.filter(
        (city) => city.toLowerCase().indexOf(searchText) >= 0
      )
    );
  };
  return (
    <div>
      <Header component={component} />
      <div className="upcoming-class-container">
        <div className="city-searchbar">
          <input
            type="text"
            className="form-control"
            placeholder="search . . ."
            onChange={(e) => search(e)}
          />
          <i className="fas fa-search"></i>
        </div>
        <div className="course-card-container">
          {searchResult.map((city, index) => {
            return (
              <Link
                className="course-card animate__animated animate__fadeIn"
                key={index}
                to={`/${city}/upcomingclass`}
              >
                <div>
                  <i className="fas fa-map-marked-alt"></i>
                </div>
                <div>
                  <p>{city}</p>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Cities;
