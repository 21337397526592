import axios from "axios";
import dayjs from "dayjs";
import React from "react";
import { useCallback, useEffect, useState } from "react";

import ClassCard from "../../components/ClassCard/ClassCard.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Header/Header.jsx";
import Loading2 from "../../components/Loading/Loading2.jsx";

import "./UpcomingClass.scss";

const UpcomingClass = ({ city, component }) => {
  const [upcomingCourseClasses, setUpcomingCourseClasses] = useState(null);

  const sortAndAddWeekNumber = (classes) => {
    let counter = 0;
    if (classes[0] && !classes[0].weekNumber) {
      classes = classes
        .sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1))
        .map((c) => {
          c.weekNumber = c.scheduleType === "Holiday" ? "-" : ++counter;
          return c;
        });
    }
    return classes;
  };

  const getUpcomingClass = useCallback(async () => {
    let filteredCourses = null;
    let courses = null;
    try {
      await axios.get(`/courses/${city}`).then(async (response) => {
        if (response.data.data.length > 0) {
          courses = response.data.data;
          courses = courses.map((course) => {
            course.classes = sortAndAddWeekNumber(course.classes);
            return course;
          });
          filteredCourses = courses.filter((course) => {
            course.nextClass = course.classes.find(
              (class_) =>
                class_.scheduleType !== "Holiday" &&
                dayjs(class_.date) > dayjs(new Date())
            );
            return course.nextClass !== undefined;
          });
          setUpcomingCourseClasses(filteredCourses);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }, [city]);
  useEffect(() => {
    getUpcomingClass();
  }, [getUpcomingClass]);
  // console.log(upcomingCourseClasses);
  return (
    <div>
      <Header city={city} component={component} />
      <div className="upcoming-class-container">
        {upcomingCourseClasses && upcomingCourseClasses.length > 0 ? (
          upcomingCourseClasses.map((upcomingCourse, index) => (
            <div key={index}>
              <div className="page-title">
                <p>{city}</p> <i className="fas fa-chevron-right"></i>
                <p>{upcomingCourse.intakeName}</p>
                <i className="fas fa-chevron-right"></i>
                <p>Upcoming Class</p>
              </div>
              <ClassCard
                key={index}
                city={city}
                component={component}
                Class={upcomingCourse.nextClass}
                WeekNumber={upcomingCourse.nextClass.weekNumber}
              />
            </div>
          ))
        ) : (
          <div className="page-title">
            <p>{city}</p> <i className="fas fa-chevron-right"></i>
            <p>Upcoming Class</p>
            <Loading2 loaded={upcomingCourseClasses !== null} />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default UpcomingClass;
