import axios from "axios";
import React from "react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Header/Header.jsx";
import Loading2 from "../../components/Loading/Loading2.jsx";
import LookupsContext from "../../context/LookupsContext.js";
import UserContext from "../../context/UserContext.js";
import "./UsersPage.scss";

const UsersPage = ({ component }) => {
  const adminUser = useContext(UserContext);
  const lookups = useContext(LookupsContext);
  const navigate = useNavigate();
  //   const [alertMessage, setAlertMessage] = useState(null);
  //   const [alertStatus, setAlertStatus] = useState(false);
  const [users, setUsers] = useState(null);
  const [savedUser, setSavedUser] = useState(null);

  useEffect(() => {
    try {
      axios.get("/users").then((results) => {
        if (results.data.data.length > 0) {
          setUsers(results.data.data);
        } else {
          navigate("/");
        }
      });
    } catch (err) {
      navigate("/");
    }
  }, [navigate]);

  if (adminUser.role !== "Administrator") {
    navigate("/");
    return;
  }

  const edit = (id) => {
    if (savedUser) {
      // currently editing
      setUsers((users) =>
        users.map((user) => (user._id === savedUser._id ? savedUser : user))
      ); // reset user to original
      if (savedUser._id === id) setSavedUser(null);
      // not editing anyone now
      else setSavedUser(users.find((user) => user._id === id)); // save original values in user
    } else {
      setSavedUser(users.find((user) => user._id === id)); // save original values in user
    }
  };

  const save = () => {
    axios
      .put(
        "/users",
        users.find((user) => user._id === savedUser._id)
      )
      .then(setSavedUser(null))
      .catch((error) => {
        console.log(error);
        edit(savedUser._id); // cancel edit to alert that changes have not been saved.
      });
  };

  return (
    <div>
      <Header component={component} />
      <div className="users-container">
        <div className="page-title">
          <p>Users</p>
        </div>
        {/* {alertStatus && <Alert type={alertType}> {alertMessage} </Alert>} */}

        <div className="table-responsive animate__animated animate__fadeIn">
          <table className="table" cellSpacing="0" cellPadding="0">
            <thead>
              <tr className="header-tr">
                <th scope="col">Display name</th>
                <th scope="col">Github username</th>
                <th scope="col">Role</th>
                <th scope="col">Home city</th>
                <th scope="col">Validated</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {users && users.length > 0 ? (
                users.map((user) => {
                  console.log(user);
                  return (
                    <tr
                      key={user._id}
                      className={
                        savedUser && savedUser._id === user._id ? "editing" : ""
                      }
                    >
                      <td>
                        {user.displayName
                          ? user.displayName
                          : user.githubDisplayName}
                      </td>
                      <td>{user.username}</td>
                      <td>
                        <select
                          id={`role_${user._id}`}
                          value={user.role}
                          onChange={(e) => {
                            if (savedUser && savedUser._id === user._id) {
                              const newValue = e.target.value;
                              setUsers((users) =>
                                users.map((u) =>
                                  u._id === user._id
                                    ? { ...user, role: newValue }
                                    : u
                                )
                              );
                            }
                          }}
                        >
                          <option key="Not set" value="">
                            Not set
                          </option>
                          <option key="Administrator" value="Administrator">
                            Administrator
                          </option>
                          <option key="Volunteer" value="Volunteer">
                            Volunteer
                          </option>
                          <option key="Trainee" value="Trainee">
                            Trainee
                          </option>
                        </select>
                      </td>
                      <td>
                        <select
                          id={`city_${user._id}`}
                          value={user.homeCity}
                          onChange={(e) => {
                            if (savedUser && savedUser._id === user._id) {
                              const newValue = e.target.value;
                              setUsers((users) =>
                                users.map((u) =>
                                  u._id === user._id
                                    ? { ...user, homeCity: newValue }
                                    : u
                                )
                              );
                            }
                          }}
                        >
                          <option key="Not set" value="">
                            Not set
                          </option>
                          {lookups.cities.map((city) => (
                            <option key={city} value={city}>
                              {city}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          checked={user.roleValidated}
                          onChange={(e) => {
                            if (savedUser && savedUser._id === user._id) {
                              const newValue = e.target.checked;
                              setUsers((users) =>
                                users.map((u) =>
                                  u._id === user._id
                                    ? { ...user, roleValidated: newValue }
                                    : u
                                )
                              );
                            }
                          }}
                        />
                      </td>
                      <td>
                        {savedUser ? (
                          savedUser._id === user._id ? (
                            <>
                              <i
                                className="far fa-check-circle"
                                tooltip="Save"
                                onClick={() => save()}
                              />{" "}
                              <i
                                className="far fa-times-circle"
                                tooltip="Cancel"
                                onClick={() => edit(user._id)}
                              />
                            </>
                          ) : (
                            <p></p>
                          )
                        ) : (
                          <button onClick={() => edit(user._id)}>edit</button>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>
                    <Loading2 loaded={users !== null} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UsersPage;
