import axios from "axios";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Header/Header.jsx";
import UserContext from "../../context/UserContext";
import "./Home.scss";

const Home = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  if (user && (!user.role || !user.homeCity)) {
    navigate("/account");
  }
  return (
    <div className="home_container">
      <Header component={"home"} />
      <div className="home-main">
        <div>
          {user && (
            <p>
              Welcome{" "}
              {user.displayName ? user.displayName : user.githubDisplayName}!
              <a href={axios.defaults.baseURL + "/auth/logout"}>Log out</a>
            </p>
          )}
        </div>
        <div>
          {!user && (
            <button className="github-login">
              <a href={axios.defaults.baseURL + "/auth/github"}>
                Login using Github
              </a>
            </button>
          )}
        </div>
        <div>
          <img className="home-image" src="../files/Home.png" alt="CYF" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
