import React from "react";
import { useContext, useState } from "react";

import UserContext from "../../context/UserContext";
import Alert from "../Alert/Alarm.jsx";
import Loading2 from "../Loading/Loading2.jsx";

import CancelBookingAlert from "./CancelBookingAlert.jsx";
import "./ClassVolunteersList.scss";

const ClassVolunteersList = ({ city, id, WeekNumber, bookings }) => {
  const [ConfirmationStatus, setConfirmationStatus] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("message");
  const [fullName, setFullName] = useState("");
  const [bookingId, setBookingId] = useState("");

  const user = useContext(UserContext);

  function cancelBookingHandler(username, bookingId) {
    if (["Administrator", "Volunteer"].includes(user.role))
      setConfirmationStatus(true);
    setFullName(username);
    setBookingId(bookingId);
  }

  function closeConfirmationAlert() {
    setConfirmationStatus(false);
  }

  function showAlert(type, message) {
    setAlertStatus(true);
    setAlertType(type);
    setAlertMessage(message);
  }

  console.log(user);
  return (
    <div className="classvolunteerslist-container table-responsive">
      {ConfirmationStatus && (
        <CancelBookingAlert
          user={user}
          city={city}
          id={id}
          fullName={fullName}
          _id={bookingId}
          closeHandler={closeConfirmationAlert}
          showAlert={showAlert}
          WeekNumber={WeekNumber}
        />
      )}
      {alertStatus && <Alert type={alertType}> {alertMessage} </Alert>}
      {bookings && bookings.length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Role</th>
              <th scope="col">Period</th>
              {/* {user.role === "Administrator" && <th scope="col">Email</th>} */}
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {bookings.map((volunteer, index) => (
              <tr key={index}>
                <td>
                  <div>
                    {volunteer.photoUrl && (
                      <img src={volunteer.photoUrl} alt="profile" />
                    )}
                    <span>{volunteer.username}</span>
                  </div>
                </td>
                <td>{volunteer.roleName}</td>
                <td>{volunteer.period}</td>
                <td>
                  {(user.role === "Administrator" ||
                    user.name === volunteer.username) && (
                    <button
                      className="btn-cancel-volunteer"
                      onClick={() => {
                        setAlertStatus(false);
                        cancelBookingHandler(volunteer.username, volunteer._id);
                      }}
                    >
                      Cancel
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {(!bookings || bookings.length === 0) && (
        <Loading2 loaded={bookings === null} />
      )}
    </div>
  );
};

export default ClassVolunteersList;
