import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import ClassCard from "../../components/ClassCard/ClassCard.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Header/Header.jsx";
import Loading from "../../components/Loading/Loading.jsx";

const ClassVolunteers = ({ city, component, id, WeekNumber }) => {
  const [Class, setClass] = useState(null);
  const navigate = useNavigate();
  const getClass = useCallback(async () => {
    await axios.get("/classes/").then((response) => {
      if (response.data.data.length > 0) {
        let classFound = response.data.data.find((Class) => Class._id === id);
        if (classFound) {
          setClass(classFound);
        } else {
          navigate(`/`);
        }
      } else {
        navigate(`/`);
      }
    });
  }, [id, navigate]);
  useEffect(() => {
    getClass();
  }, [getClass]);
  return (
    <div>
      <Header city={city} component={component} />
      <div className="upcoming-class-container">
        <div className="page-title">
          <p>{city}</p> <i className="fas fa-chevron-right"></i>
          <p>Attending Volunteers</p>
        </div>
        {Class ? (
          <ClassCard
            id={id}
            city={city}
            component={component}
            Class={Class}
            WeekNumber={WeekNumber}
          />
        ) : (
          <Loading />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ClassVolunteers;
