import React from "react";
import "./NotFound.scss";

const NotFound = () => {
  return (
    <div className="not-found">
        <p>OOPS</p>
        <p>ERROR 404</p>
        <p>PAGE NOT FOUND</p>
    </div>
  );
};

export default NotFound;
