import React from 'react'

import Footer from '../../components/Footer/Footer.jsx'
import Header from '../../components/Header/Header.jsx'
import Loading2 from '../../components/Loading/Loading2.jsx'

import Calendar from './Calendar.jsx'
import useGetCohorts from './useGetCohorts.jsx'

import './GlobalCalendar.scss'

const GlobalCalendar = () => {
  const { data, loading, success } = useGetCohorts()

  return (
    <div>
      <Header component='globalcalendar' />
      {success && <Calendar data={data} />}
      {loading && <Loading2 loaded={false} />}
      {!loading && !success && <Loading2 loaded={true} />}
      <Footer />
    </div>
  )
}

export default GlobalCalendar
