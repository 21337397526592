import React from "react";

import Overview from "../../components/Overview/Overview.jsx";
import "./Overview.scss";

const OverviewPage = () => {
  return (
    <div className="coursecalendar-container">
      <Overview component={"overview"} />
    </div>
  );
};

export default OverviewPage;
