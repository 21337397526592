import dayjs from "dayjs";
import React from "react";

import Alert from "../../components/Alert/Alarm.jsx";
import "./CourseForm.scss";

const CourseForm = ({
  city,
  component,
  alertMessage,
  entryData,
  error,
  _onChange,
  _onSubmit,
  intakeName,
  startDate,
  endDate,
}) => {
  return (
    <div className="new-course-calendar-container">
      <div className="page-title">
        <p>{city}</p> <i className="fas fa-chevron-right"></i>
        <p>{component === "newcourse" ? "New Course" : "Edit Course"}</p>
      </div>
      <form
        className="new-course-calendar-form"
        noValidate
        onSubmit={_onSubmit}
      >
        {alertMessage && alertMessage !== "" ? (
          <Alert type={alertMessage.type} children={alertMessage.message} />
        ) : null}
        <div className="form-group font-size">
          <label htmlFor="intakeName">Intake: </label>
          <input
            name="intakeName"
            id="intakeName"
            type="text"
            defaultValue={intakeName}
            onChange={(e) => _onChange(e)}
            placeholder="YY-Course-ID (e.g. 24-ITD-01)"
            className={
              error && error.intakeName ? "form-control error" : "form-control"
            }
            ref={(e) =>
              (entryData.current[0] = {
                element: e,
                required: true,
              })
            }
          ></input>
        </div>
        <div className="err-msg">
          {error && error.intakeName && <p> *&nbsp;Intake Name is required!</p>}
        </div>
        <div className="form-group font-size">
          <label>Start Date: </label>
          <input
            type="date"
            name="startDate"
            defaultValue={dayjs(new Date(startDate)).format("YYYY-MM-DD")}
            onChange={(e) => _onChange(e)}
            className={
              error && error.startDate ? "form-control error" : "form-control"
            }
            ref={(e) =>
              (entryData.current[2] = {
                element: e,
                required: true,
              })
            }
          ></input>
        </div>
        <div className="err-msg">
          {error && error.startDate && <p> *&nbsp;Start Date is required!</p>}
        </div>
        <div className="form-group font-size">
          <label>End Date: </label>
          <input
            type="date"
            name="endDate"
            defaultValue={dayjs(new Date(endDate)).format("YYYY-MM-DD")}
            onChange={(e) => _onChange(e)}
            className={
              error && error.endDate ? "form-control error" : "form-control"
            }
            ref={(e) =>
              (entryData.current[3] = {
                element: e,
                required: true,
              })
            }
          ></input>
        </div>
        <div className="err-msg">
          {error && error.endDate && <p> *&nbsp;End Date is required!</p>}
        </div>
        <div className="form-group font-size">
          <input type="submit" className="form-control"></input>
        </div>
      </form>
    </div>
  );
};

export default CourseForm;
