import axios from 'axios'
import { useContext, useEffect, useState } from 'react'

import LookupsContext from '../../context/LookupsContext.js'

const useGetCohorts = () => {
  const lookups = useContext(LookupsContext)

  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const promisCohorts = lookups.cities.map((city) =>
          axios.get(`/courses/${city}`)
        )

        const axiosResponses = await Promise.all(promisCohorts)
        const filteredCohorts = axiosResponses.flatMap(({ data: axiosResponse }) => axiosResponse.data)

        setError(false)
        setSuccess(true)
        setData(filteredCohorts)
      } catch (err) {
        setError(err.message)
        setSuccess(false)
        console.log(err)
      } finally{
        setLoading(false)
      }
    }

    fetchData()
  }, [lookups.cities])

  return {data, loading, success, error }
}

export default useGetCohorts
