import axios from "axios";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import React from "react";
import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ClassForm from "../../components/ClassForm/ClassForm.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Header/Header.jsx";
import Loading from "../../components/Loading/Loading.jsx";
import { useForm } from "../../hooks/useForm.jsx";
import "./EditClassPage.scss";

const EditClassPage = ({ city, component, id }) => {
  const [courses, setCourses] = useState(null);
  const [currentClass, setCurrentClass] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const navigate = useNavigate();
  dayjs.extend(isBetween);

  const getClass = useCallback(async () => {
    const classData = await axios.get(`/classes/${id}`);
    if (!classData || classData.data.data.length < 1) {
      navigate(`/`);
    } else {
      setCurrentClass(classData.data.data[0]);
    }
  }, [id, navigate]);

  const getCourses = useCallback(async () => {
    const coursesData = await axios.get(`/courses/${city}`);
    if (coursesData.data.data.length > 0) {
      setCourses(coursesData.data.data);
    } else {
      navigate(`/`);
    }
  }, [city, navigate]);

  useEffect(() => {
    getClass();
  }, [getClass]);
  
  useEffect(() => {
    if (currentClass) {
      getCourses();
    }
  }, [getCourses, currentClass]);
  
  const editClass = async (values) => {
    let duplicate = false;
    if (values.status === currentClass.status) {
      if (values.status) {
        if (
          values.courseCalendar_Id === currentClass.courseCalendar_Id &&
          dayjs(currentClass.date).format("MM-DD-YYYY") ===
            dayjs(values.date).format("MM-DD-YYYY") &&
          values.className === currentClass.className &&
          values.startTime === currentClass.startTime &&
          values.endTime === currentClass.endTime &&
          values.agendaURL === currentClass.agendaURL &&
          values.syllabusURL === currentClass.syllabusURL &&
          values.scheduleType === currentClass.scheduleType &&
          values.courseModule === currentClass.courseModule
        ) {
          duplicate = true;
        }
      } else {
        if (
          values.courseCalendar_Id === currentClass.courseCalendar_Id &&
          dayjs(currentClass.date).format("MM-DD-YYYY") ===
            dayjs(values.date).format("MM-DD-YYYY") &&
          values.className === currentClass.className
        ) {
          duplicate = true;
        }
      }
    }
    if (duplicate) {
      setUnsubmitted();
      setAlertMessage({
        type: "danger",
        message: "There is no change to update !",
      });
    } else if (
      Date.parse(`01/01/2020 ${values.startTime}:00`) >=
      Date.parse(`01/01/2020 ${values.endTime}`)
    ) {
      setUnsubmitted();
      setAlertMessage({
        type: "danger",
        message: "End Time must be after Start Time!",
      });
    } else if (
      dayjs(values.date) <= dayjs(new Date()) &&
      dayjs(currentClass.date).format("MM-DD-YYYY") !==
        dayjs(values.date).format("MM-DD-YYYY")
    ) {
      setUnsubmitted();
      setAlertMessage({
        type: "danger",
        message: "Date is not valid!",
      });
    } else {
      const outOfDate = courses.find(
        (course) =>
          dayjs(values.date).isBetween(
            dayjs(course.startDate),
            dayjs(course.endDate),
            "day",
            "[]"
          ) && course._id === values.courseCalendar_Id
      );
      if (!outOfDate) {
        setUnsubmitted();
        setAlertMessage({
          type: "danger",
          message: "This Date is out of the course period!",
        });
      } else {
        await axios
          .put(`/classes/${id}`, {
            ...values,
          })
          .then((response) => {
            if (response.data.success) {
              setAlertMessage({
                type: "success",
                message: "Class updated successfully !",
              });
              setTimeout(() => {
                navigate(`/${city}/coursecalendar/`);
              }, 1000);
            } else {
              setUnsubmitted();
              setAlertMessage({
                type: "danger",
                message: "Class is not updated !",
              });
            }
          });
      }
    }
  };
  const { entryData, error, onChange, onSubmit, setUnsubmitted } =
    useForm(editClass);
  return (
    <div>
      <Header city={city} component={component} />
      <div className="editclass-container">
        {courses && currentClass ? (
          <ClassForm
            city={city}
            component={component}
            courses={courses}
            entryData={entryData}
            alertMessage={alertMessage}
            error={error}
            _onChange={onChange}
            _onSubmit={onSubmit}
            date={currentClass.date}
            courseCalendar_Id={currentClass.courseCalendar_Id}
            // status={currentClass.status}
            className={currentClass.className}
            startTime={currentClass.startTime}
            courseModule={currentClass.courseModule}
            endTime={currentClass.endTime}
            agendaURL={currentClass.agendaURL}
            syllabusURL={currentClass.syllabusURL}
            scheduleType={currentClass.scheduleType}
          />
        ) : (
          <Loading />
        )}
      </div>
      <Footer />
    </div>
  );
};
export default EditClassPage;
