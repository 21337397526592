import axios from "axios";
import React from "react";

import "./CancelCourse.scss";

const CancelClass = ({ city, currentCourse, closeHandler, showAlert }) => {
  function deleteClass() {
    axios
      .delete(`/courses/${currentCourse._id}`)
      .then(function (response) {
        if (response.data.success === true) {
          closeHandler();
          showAlert("success", "The class has been deleted successfully.");
          setTimeout(() => {
            window.location.replace(`/${city}/courses/`);
          }, 1000);
        }
      })
      .catch(function (err) {
        closeHandler();
        if (err.response.data.success === false) {
          showAlert("danger", err.response.data.error);
        }
      });
  }
  return (
    <div className="cancel-alert">
      <p>Are you sure to cancel the corresponding Course?</p>
      <div>
        <button className="cancel-no" onClick={() => closeHandler(true)}>
          NO
        </button>
        <button className="cancel-yes" onClick={deleteClass}>
          YES
        </button>
      </div>
    </div>
  );
};

export default CancelClass;
