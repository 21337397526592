import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import Alert from "../../components/Alert/Alarm.jsx";
import LookupsContext from "../../context/LookupsContext";
import { useForm } from "../../hooks/useForm.jsx";
import "./NewBookingForm.scss";

const NewBookingForm = ({ Class, city, WeekNumber, user }) => {
  const lookups = useContext(LookupsContext);
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState(null);
  const displayName = user.displayName || user.githubDisplayName;

  const NewSignUp = async (values) => {
    values.user = user._id;
    values.class = Class._id;

    await axios
      .post("/bookings", {
        ...values,
      })
      .then((response) => {
        if (response.data.success) {
          setAlertMessage({
            type: "success",
            message: "New class booking is created successfully !",
          });
          setTimeout(() => {
            navigate(`/${city}/attendingvolunteers/${Class._id}/${WeekNumber}`);
          }, 1000);
        } else {
          setUnsubmitted();
          setAlertMessage({
            type: "danger",
            message: "New class booking is not created !",
          });
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          setUnsubmitted();
          setAlertMessage({
            type: "danger",
            message: err.response.data.message,
          });
        }
      });
  };

  const { entryData, error, onChange, onSubmit, setUnsubmitted } =
    useForm(NewSignUp);
  return (
    <div className="newbooking-container">
      <form className="newbooking-form" noValidate onSubmit={onSubmit}>
        {alertMessage && alertMessage !== "" ? (
          <Alert type={alertMessage.type} children={alertMessage.message} />
        ) : null}
        <div className="form-group">
          <label>Full Name:</label>
          <input type="text" value={displayName} readOnly />
        </div>
        <div className="form-group">
          <label htmlFor="roleName">Role:</label>
          <select
            type="text"
            name="roleName"
            id="roleName"
            onChange={(e) => onChange(e)}
            className={
              error && error.roleName ? "form-control error" : "form-control"
            }
            placeholder="Role Name . . ."
            ref={(e) =>
              (entryData.current[0] = {
                element: e,
                required: true,
              })
            }
          >
            <option key="" value=""></option>
            {lookups.classRoles.map((role) => (
              <option key={role} value={role}>
                {role}
              </option>
            ))}
          </select>
        </div>
        <div className="err-msg">
          {error && error.roleName && <p> * {error.roleName}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="allDay">Period:</label>
          <div className="form-radio">
            <div>
              <input
                type="radio"
                id="allDay"
                name="period"
                value="All day"
                defaultChecked
                onChange={(e) => onChange(e)}
                ref={(e) =>
                  (entryData.current[1] = {
                    element: e,
                    required: false,
                  })
                }
              />
              <label htmlFor="allDay">All&nbsp;day</label>
            </div>
            <div>
              <input
                type="radio"
                id="amOnly"
                name="period"
                value="A.M."
                onChange={(e) => onChange(e)}
                ref={(e) =>
                  (entryData.current[2] = {
                    element: e,
                    required: false,
                  })
                }
              />
              <label htmlFor="amOnly">Morning</label>
            </div>
            <div>
              <input
                type="radio"
                id="pmOnly"
                name="period"
                value="P.M."
                onChange={(e) => onChange(e)}
                ref={(e) =>
                  (entryData.current[3] = {
                    element: e,
                    required: false,
                  })
                }
              />
              <label htmlFor="pmOnly">Afternoon</label>
            </div>
          </div>
        </div>
        <div className="form-group check-box-container">
          <label htmlFor="isNewVolunteer">First Time Volunteer</label>
          <input
            type="checkbox"
            id="isNewVolunteer"
            name="isNewVolunteer"
            onChange={(e) => onChange(e)}
            ref={(e) =>
              (entryData.current[4] = {
                element: e,
                required: false,
              })
            }
          />
        </div>
        <div className="form-group">
          <input type="submit" />
        </div>
      </form>
    </div>
  );
};

export default NewBookingForm;
