import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import LookupsContext from "./context/LookupsContext";
import UserContext from "./context/UserContext";
import AppRoutes from "./routes";

axios.defaults.baseURL = "/api/v1";

const App = () => {
  const [user, setUser] = useState(null);
  const [lookups, setLookups] = useState(null);
  useEffect(() => {
    const fetchBoth = async () => {
      try {
        const lookupsData = await axios.get("/lookups");
        console.log(lookupsData);
        setLookups(lookupsData.data.payload);
      } catch (error) {
        setLookups(null);
        console.log(error);
      }
      try {
        const userData = await axios.get("/account");
        console.log(userData);
        setUser(userData.data.payload);
      } catch (error) {
        setUser(null);
        console.log(error);
      }
    };
    fetchBoth();
  }, []);
  return (
    <LookupsContext.Provider value={lookups}>
      <UserContext.Provider value={user}>
        <div className="app">
          <Router>
            <AppRoutes setUser={setUser} />
          </Router>
        </div>
      </UserContext.Provider>
    </LookupsContext.Provider>
  );
};

export default App;
