import React from "react";

import Alert from "../../components/Alert/Alarm.jsx";
import "./AccountForm.scss";

// READ-ONLY
//   githubId,
//   githubDisplayName,
//   githubEmail,
//   username,
//   location,
//   phone,
//   profilePhoto,
//   roleValidated
// EDITABLE
//   displayName,
//   email,
//   showProfilePhoto,
//   homeCity
//   role, only if not already validated

const AccountForm = ({
  user,
  cities,
  entryData,
  alertMessage,
  error,
  _onChange,
  _onSubmit,
}) => {
  // console.log(user);
  return (
    <div className="account-container">
      <div className="page-title">
        <p>{user.username}</p>
      </div>
      <form className="account-form" noValidate onSubmit={_onSubmit}>
        {alertMessage && alertMessage !== "" && (
          <Alert type={alertMessage.type} children={alertMessage.message} />
        )}
        <div className="form-group">
          <label htmlFor="display_name">Display name: </label>
          <input
            id="display_name"
            name="displayName"
            type="text"
            defaultValue={user.displayName}
            placeholder={user.githubDisplayName}
            className={
              error && error.display_name
                ? "form-control error"
                : "form-control"
            }
            onChange={(e) => _onChange(e)}
            ref={(e) =>
              (entryData.current[0] = {
                element: e,
                required: false,
              })
            }
          />
        </div>
        <div className="err-msg">
          {error && error.displayName && (
            <p>* Display name must be empty or at least 4 characters</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            id="email"
            name="email"
            placeholder={user.githubEmail}
            defaultValue={user.email}
            className={
              error && error.email ? "form-control error" : "form-control"
            }
            type="email"
            onChange={(e) => _onChange(e)}
            ref={(e) =>
              (entryData.current[1] = {
                element: e,
                required: false,
              })
            }
          />
        </div>
        <div className="err-msg">
          {error && error.email && (
            <p>* email must be empty or a valid email address</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="role">Role:</label>
          {user.roleValidated ? (
            <div>
              <input id="role" type="text" value={user.role} readOnly />
            </div>
          ) : (
            <select
              id="role"
              name="role"
              required
              defaultValue={user.role}
              className={
                error && error.role ? "form-control error" : "form-control"
              }
              onChange={(e) => _onChange(e)}
              ref={(e) =>
                (entryData.current[2] = {
                  element: e,
                  required: true,
                })
              }
            >
              <option key="Not set" value="">
                Not set
              </option>
              {/* <option key="Administrator" value="Administrator">
                Administrator
              </option> */}
              <option key="Volunteer" value="Volunteer">
                Volunteer
              </option>
              <option key="Trainee" value="Trainee">
                Trainee
              </option>
            </select>
          )}
        </div>
        <div className="err-msg">
          {error && error.role && <p>* a role must be selected</p>}
        </div>
        <div className="form-group">
          <label htmlFor="homeCity">Home city:</label>
          <select
            id="homeCity"
            name="homeCity"
            required
            defaultValue={user.homeCity}
            className={
              error && error.homeCity ? "form-control error" : "form-control"
            }
            onChange={(e) => _onChange(e)}
            ref={(e) =>
              (entryData.current[3] = {
                element: e,
                required: true,
              })
            }
          >
            <option key="Not set" value="">
              Not set
            </option>
            {cities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>
        <div className="err-msg">
          {error && error.homeCity && <p>* a home city must be selected</p>}
        </div>
        <div className="form-group">
          <label>Avatar:</label>
          <div>
            <img src={user.profilePhoto} alt="avatar" />
            <label htmlFor="avatar">display?:</label>
            <input
              id="avatar"
              name="showProfilePhoto"
              type="checkbox"
              className="form-control"
              defaultChecked={user.showProfilePhoto}
              onChange={(e) => _onChange(e)}
              ref={(e) =>
                (entryData.current[4] = {
                  element: e,
                  required: false,
                })
              }
            />
          </div>
        </div>
        <div className="form-group">
          <input type="submit" />
        </div>
      </form>
    </div>
  );
};

export default AccountForm;
