import axios from "axios";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

export const useFilter = () => {
  const [data, setData] = useState(null);
  const entryData = useRef([]);
  const navigate = useNavigate();
  dayjs.extend(isBetween);

  const sortAndAddWeekNumber = (classes) => {
    let counter = 0;
    if (classes[0] && !classes[0].weekNumber) {
      classes = classes
        .sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1))
        .map((c) => {
          c.weekNumber = c.scheduleType === "Holiday" ? "-" : ++counter;
          return c;
        });
    }
    return classes;
  };

  const filter = async () => {
    try {
      let classes = data.courses.find(
        (c) => c._id === entryData.current[1].value
      ).classes;
      if (classes.length > 0) {
        // classes = sortAndAddWeekNumber(classes);
        classes = classes.filter(
          (c) =>
            (dayjs(c.date).format("M") === entryData.current[2].value ||
              entryData.current[2].value === "All-Months") &&
            (c.className
              .toLowerCase()
              .indexOf(entryData.current[0].value.toLowerCase()) >= 0 ||
              c.scheduleType
                .toLowerCase()
                .indexOf(entryData.current[0].value.toLowerCase()) >= 0)
        );
      }
      setData({
        courseId: entryData.current[1].value,
        courses: data.courses,
        classes: classes,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async (city) => {
    try {
      const coursesData = await axios(`/courses/${city}`);
      const courses = coursesData.data.data.sort();
      courses.forEach((course) => {
        course.classes = sortAndAddWeekNumber(course.classes);
      });
      if (courses.length > 0) {
        let currentCourse = courses.find((course) =>
          dayjs(new Date()).isBetween(
            dayjs(course.startDate),
            dayjs(course.endDate),
            "day"
          )
        );
        if (!currentCourse) {
          currentCourse = courses[courses.length - 1];
        }
        let classes = [...currentCourse.classes];
        if (classes.length > 0) {
          // classes = sortAndAddWeekNumber(classes);
          classes = classes.filter(
            (class_) =>
              dayjs(class_.date).format("M") === dayjs(new Date()).format("M")
          );
        }
        setData({
          courseId: currentCourse._id,
          courses: courses,
          classes: classes,
        });
      } else {
        navigate("/nothing");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    entryData,
    getData,
    filter,
    data,
  };
};
