import React from "react";

import CourseCalendar from "../../components/CourseCalendar/CourseCalendar.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Header/Header.jsx";
import "./CourseCalendar.scss";

const CourseCalendarPage = ({ city, component }) => {
  return (
    <div>
      <Header city={city} component={component} />
      <div className="coursecalendar-container">
        <CourseCalendar city={city} component={component} />
      </div>
      <Footer />
    </div>
  );
};

export default CourseCalendarPage;
