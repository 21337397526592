import axios from "axios";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import React from "react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ClassForm from "../../components/ClassForm/ClassForm.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Header/Header.jsx";
import { useForm } from "../../hooks/useForm.jsx";
import "./NewClassPage.scss";

const NewClassPage = ({ city, component }) => {
  const [courses, setCourses] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const navigate = useNavigate();
  dayjs.extend(isBetween);

  const getCourses = useCallback(async () => {
    const coursesData = await axios.get(`/courses/${city}`);
    console.log("NCP", coursesData);
    if (coursesData.data.data.length > 0) {
      setCourses(coursesData.data.data);
    } else {
      navigate(`/`);
    }
  }, [city, navigate]);
  const newClass = async (values) => {
    if (
      Date.parse(`01/01/2020 ${values.startTime}:00`) >=
      Date.parse(`01/01/2020 ${values.endTime}`)
    ) {
      setUnsubmitted();
      setAlertMessage({
        type: "danger",
        message: "End Time must be after Start Time!",
      });
    } else if (dayjs(values.date) <= dayjs(new Date())) {
      setUnsubmitted();
      setAlertMessage({
        type: "danger",
        message: "Date must be in the future!",
      });
    } else {
      const outOfDate = courses.find(
        (course) =>
          dayjs(values.date).isBetween(
            dayjs(course.startDate),
            dayjs(course.endDate),
            "day",
            "[]"
          ) && course._id === values.courseCalendar_Id
      );
      if (!outOfDate) {
        setUnsubmitted();
        setAlertMessage({
          type: "danger",
          message: "This Date is outside of the course period!",
        });
      } else {
        console.log(values);
        await axios
          .post("/classes", {
            ...values,
          })
          .then((response) => {
            if (response.data.success) {
              setAlertMessage({
                type: "success",
                message: "New class added successfully !",
              });
              setTimeout(() => {
                navigate(`/${city}/coursecalendar/`);
              }, 1000);
            } else {
              setUnsubmitted();
              setAlertMessage({
                type: "danger",
                message: "New class has not been added !",
              });
            }
          })
          .catch((err) => {
            if (!err.response.data.success) {
              setAlertMessage({
                type: "danger",
                message: err.response.data.message,
              });
            }
          });
      }
    }
  };
  const { entryData, error, onChange, onSubmit, setUnsubmitted } =
    useForm(newClass);
  useEffect(() => {
    getCourses();
  }, [getCourses]);
  return (
    <div>
      <Header city={city} component={component} />
      <div className="newclass-container">
        {courses && (
          <ClassForm
            city={city}
            component={component}
            courses={courses}
            entryData={entryData}
            alertMessage={alertMessage}
            error={error}
            _onChange={onChange}
            _onSubmit={onSubmit}
          />
        )}
      </div>
      <Footer />
    </div>
  );
};
export default NewClassPage;
