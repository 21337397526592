import dayjs from 'dayjs'
import React, { useMemo } from 'react'

const Calendar = ({ data }) => {
  const operationlCohorts = useMemo(
    () =>
      data.filter((cohort) =>
        dayjs(cohort.endDate).isAfter(dayjs().startOf('month'))
      ),
    [data]
  )
  const uniqueClassDates = useMemo(() => {
    if (!operationlCohorts) return []
    const classDates = operationlCohorts.flatMap((cohort) =>
      cohort.classes.flatMap((c) => dayjs(c.date).isAfter(dayjs().startOf('month')) ? 
                                    dayjs(c.date).startOf('D').toISOString() : 
                                    [])
    )
    return Array.from(new Set(classDates)).sort((a, b) => dayjs(a).diff(b))
  }, [operationlCohorts])

  return (
    <div className='global-calendar-container'>
      <table className='global-calendar-table'>
        <thead>
          <tr className='table-title'>
            <th scope='col'>Class Date</th>
            {operationlCohorts.map((course) => (
              <th key={course.id}>{course.intakeName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {uniqueClassDates.map((date) => (
            <tr data-testid={dayjs(date).format('DD/MM/YYYY')} key={date}>
              <th scope='row'>{dayjs(date).format('DD/MM/YYYY')}</th>
              {operationlCohorts.map((cohort) => {
                const matchingClass = cohort.classes.find((c) =>
                  dayjs(c.date).isSame(date, 'day')
                )
                return (
                  <td
                    key={cohort.id}
                    className={
                      matchingClass?.scheduleType === 'Holiday' ? 'holiday' : ''
                    }
                  >
                    {matchingClass?.courseModule ??
                      matchingClass?.className ??
                      '-'}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Calendar
