import axios from "axios";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import React from "react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CourseForm from "../../components/CourseForm/CourseForm.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Header/Header.jsx";
import { useForm } from "../../hooks/useForm.jsx";

import "./EditCoursePage.scss";

const EditCoursePage = ({ city, component, id }) => {
  const [alertMessage, setAlertMessage] = useState(null);
  const [currentCourse, setCurrentCourse] = useState(null);
  const navigate = useNavigate();
  dayjs.extend(isBetween);
  const getCourses = useCallback(async () => {
    try {
      const _Courses = await axios.get(`/courses/${city}`);
      if (_Courses.data.data.length > 0) {
        let courseFound = _Courses.data.data.find(
          (Course) => Course._id === id
        );
        if (courseFound) {
          setCurrentCourse(courseFound);
        } else {
          navigate(`/`);
        }
        // return _Courses.data.data;
      } else {
        navigate(`/`);
      }
    } catch (err) {
      console.log(err);
      navigate(`/`);
    }
  }, [city, id, navigate]);
  // const getClasses = async () => {
  //   let allClasses = await axios.get("/classes/", {});
  //   if (allClasses.data.data.length > 0) {
  //     allClasses = allClasses.data.data.filter(
  //       (Class) => Class.courseCalendar_Id === id
  //     );
  //     return allClasses;
  //   } else {
  //     return [];
  //   }
  // };
  const EditCourse = async (values) => {
    if (
      values.intakeName === currentCourse.intakeName &&
      dayjs(currentCourse.startDate).format("MM-DD-YYYY") ===
        dayjs(values.startDate).format("MM-DD-YYYY") &&
      dayjs(currentCourse.endDate).format("MM-DD-YYYY") ===
        dayjs(values.endDate).format("MM-DD-YYYY")
    ) {
      setUnsubmitted();
      setAlertMessage({
        type: "danger",
        message: "There is no change to update !",
      });
    } else if (dayjs(values.startDate).isAfter(dayjs(values.endDate))) {
      setUnsubmitted();
      setAlertMessage({
        type: "danger",
        message: "End Date must be after Start Date!",
      });
    } else if (dayjs(values.endDate) <= dayjs(new Date())) {
      setUnsubmitted();
      setAlertMessage({
        type: "danger",
        message: "End Date must be in future!",
      });
    } else {
      // let _Classes = await getClasses();
      let classes = currentCourse.classes;
      if (classes.length > 0) {
        classes = classes.filter(
          (class_) =>
            !dayjs(class_.date).isBetween(
              dayjs(values.startDate),
              dayjs(values.endDate),
              "day"
            )
        );
      }
      if (classes.length > 0) {
        setUnsubmitted();
        setAlertMessage({
          type: "danger",
          message:
            "This course has some classes which are out of this date range !",
        });
      } else {
        try {
          values.city = city;
          await axios
            .put(`/courses/${id}`, {
              ...values,
            })
            .then((response) => {
              if (response.data.success) {
                setAlertMessage({
                  type: "success",
                  message: "Course updated successfully !",
                });
                setTimeout(() => {
                  navigate(`/${city}/courses/`);
                }, 1000);
              } else {
                setUnsubmitted();
                setAlertMessage({
                  type: "danger",
                  message: "Course not updated !",
                });
              }
            });
        } catch (err) {
          console.log(err);
        }
      }
    }
  };
  const { entryData, error, onChange, onSubmit, setUnsubmitted } =
    useForm(EditCourse);
  useEffect(() => {
    getCourses();
  }, [getCourses]);
  return (
    <div>
      <Header city={city} component={component} />
      <div className="NewCourseCalendar">
        {currentCourse && (
          <CourseForm
            city={city}
            component={component}
            alertMessage={alertMessage}
            entryData={entryData}
            error={error}
            _onChange={onChange}
            _onSubmit={onSubmit}
            intakeName={currentCourse.intakeName}
            cityName={currentCourse.cityName}
            startDate={currentCourse.startDate}
            endDate={currentCourse.endDate}
          />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default EditCoursePage;
