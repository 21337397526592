import axios from "axios";
import { useState } from "react";

import "./CancelBookingAlert.scss";

const CancelBookingAlert = ({ city, id, fullName, WeekNumber, user, _id, closeHandler, showAlert }) => {
  const [volunteerEmail, setVolunteerEmail] = useState("");

  function validateVolunteerEmail(event, bookingEmail) {
    event.preventDefault();

    if (bookingEmail === volunteerEmail && volunteerEmail !== "") {
      deleteBooking();
    } else if (!bookingEmail.length) {
      closeHandler();
      showAlert("danger", `You cannot delete this booking as we do not have an email address for ${fullName}`);
    } else {
      closeHandler();
      showAlert("danger", "Oh sorry! The email address is incorrect.");
    }
  }

  function deleteBooking() {
    axios
      .delete(`/bookings/${_id}`, {})
      .then(function (response) {
        if (response.data.success === true) {
          closeHandler();
          showAlert("success", "The booking has been deleted successfully.");
          setTimeout(() => {
            window.location.replace(
              `/${city}/attendingvolunteers/${id}/${WeekNumber}/`
            );
          }, 1000);
        }
      })
      .catch(function (err) {
        closeHandler();
        if (err.response.data.success === false) {
          showAlert("danger", err.response.data.error);
        }
      });
  }

  return (
    <>
      {user.role === "Administrator" && (
        <div className="cancelbooking-alert">
          <p>Are you sure to cancel the corresponding booking for {fullName} ?</p>
          <div>
            <button
              className="cancelbooking-no"
              onClick={() => closeHandler(true)}
            >
              NO
            </button>
            <button
              className="cancelbooking-yes"
              onClick={() => deleteBooking()}
            >
              YES
            </button>
          </div>
        </div>
      )}

      {user.role === "Volunteer" && (
        <div className="cancelbooking-alert">
          <p>
            Please confirm {fullName} email address to cancel the corresponding
            booking for the current class.
          </p>

          <form onSubmit={(e) => validateVolunteerEmail(e, user.email)}>
            <div>
              <input
                id="email"
                type="email"
                name="email"
                className="form-control volunteeremail-confirm"
                onChange={(e) => setVolunteerEmail(e.target.value)}
              />
              <button type="submit" className="cancelbooking-confirm">
                Confirm
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default CancelBookingAlert;
