import React, { useContext } from "react";
import {Routes, Route, useParams} from "react-router-dom";

import UserContext from "../context/UserContext";
import Account from "../screen/Account/Account.jsx";
import Cities from "../screen/Cities/Cities.jsx";
import ClassVolunteers from "../screen/ClassVolunteers/ClassVolunteers.jsx";
import CourseCalendarPage from "../screen/CourseCalendar/CourseCalendarPage.jsx";
import Courses from "../screen/Courses/Courses.jsx";
import EditClassPage from "../screen/EditClass/EditClassPage.jsx";
import EditCoursePage from "../screen/EditCourse/EditCoursePage.jsx";
import GlobalCalendar from "../screen/GlobalCalendar/GlobalCalendar.jsx";
import Home from "../screen/Home/Home.jsx";
import NewBooking from "../screen/NewBooking/NewBooking.jsx";
import NewClassPage from "../screen/NewClass/NewClassPage.jsx";
import NewCoursePage from "../screen/NewCourse/NewCoursePage.jsx";
import NotFound from "../screen/NotFound/NotFound.jsx";
import OverviewPage from "../screen/Overview/OverviewPage.jsx";
import UpcomingClass from "../screen/UpcomingClass/UpcomingClass.jsx";
import UsersPage from "../screen/Users/UsersPage.jsx";

const AppRoutes = ({ setUser }) => {
  const user = useContext(UserContext);
  if (!user || !user.username) {
    return (
      <Routes>
        <Route path="*" element={<Home />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/globalcalendar" element={<GlobalCalendar />} />
      <Route path="/:component" element={<DynamicComponent setUser={setUser} />} />
      <Route path="/:city/:component/:id?/:weeknumber?" element={<DynamicCityComponent />} />
    </Routes>
  );
};

const DynamicComponent = ({ setUser }) => {
  let { component } = useParams();

  if (!component) {
    return <NotFound />;
  }
  switch (component) {
    case "account":
      return <Account setUser={setUser} component={component} />;
    case "overview":
      return <OverviewPage />;
    case "cities":
      return <Cities component={component} />;
    case "newcourse":
      return <NewCoursePage component={component} />;
    case "user":
      return <UsersPage component={component} />;
    default:
      return <NotFound />;
  }
};

const DynamicCityComponent = () => {
  let { city, component, id, weeknumber } = useParams();

  if (!city || !component) {
    return <NotFound />;
  }
  if (["editclass", "editcourse", "attendingvolunteers", "newbooking",].includes(component) && !id) {
    return <NotFound />;
  }
  if (["attendingvolunteers", "newbooking"].includes(component) && (!id || !weeknumber)) {
    return <NotFound />;
  }
  if (["editclass", "editcourse"].includes(component)) {
    switch (component) {
      case "editclass":
        return <EditClassPage city={city} component={component} id={id} />;
      case "editcourse":
        return <EditCoursePage city={city} component={component} id={id} />;
      default:
        return <NotFound />;
    }
  }
  if (["attendingvolunteers", "newbooking"].includes(component)) {
    switch (component) {
      case "attendingvolunteers":
        return <ClassVolunteers city={city} component={component} id={id} WeekNumber={weeknumber} />;
      case "newbooking":
        return <NewBooking city={city} component={component} id={id} WeekNumber={weeknumber} />;
      default:
        return <NotFound />;
    }
  }
  switch (component) {
    case "coursecalendar":
      return <CourseCalendarPage city={city} component={component} />;
    case "upcomingclass":
      return <UpcomingClass city={city} component={component} />;
    case "newclass":
      return <NewClassPage city={city} component={component} />;
    case "courses":
      return <Courses city={city} component={component} />;
    case "newcourse":
      return <NewCoursePage city={city} component={component} />;
    default:
      return <NotFound />;
  }
};

export default AppRoutes;
