import dayjs from "dayjs";
import React, { useState, useEffect } from "react";

import { useFilter } from "../../hooks/courseCalendar/useFilter.jsx";
import { MonthNames } from "../../utils/MonthNames";
import ClassCard from "../ClassCard/ClassCard.jsx";
import Loading2 from "../Loading/Loading2.jsx";
import "./CourseCalendar.scss";

const CourseCalendar = ({ city, component }) => {
  const [showHolidays, setShowHolidays] = useState(false);
  const { entryData, getData, filter, data } = useFilter();
  useEffect(() => {
    if (data === null) {
      getData(city);
    }
  }, [city, getData, data]);
  return (
    <div className="coursecalendarform-container">
      <div className="page-title">
        <p>{city}</p> <i className="fas fa-chevron-right"></i>
        <p>Course Calendar</p>
      </div>
      <div className="filter-container">
        <div className="control-container">
          <label>Search:</label>
          <input
            type="text"
            className="form-control"
            placeholder="Search . . ."
            onChange={() => filter()}
            ref={(e) => (entryData.current[0] = e)}
          />
        </div>
        <div className="control-container">
          <label>Intake: </label>
          <select
            onChange={() => filter()}
            ref={(e) => (entryData.current[1] = e)}
            defaultValue={(data && data._id) || ""}
          >
            {data &&
              data.courses.map((course, index) => {
                return (
                  <option value={course._id} key={index}>
                    {course.intakeName}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="control-container">
          <label>Month: </label>
          <select
            defaultValue={dayjs(new Date()).format("M")}
            onChange={() => filter()}
            ref={(e) => (entryData.current[2] = e)}
          >
            <option value="All-Months">All Months</option>
            {MonthNames.map((month, index) => {
              return (
                <option value={index + 1} key={index}>
                  {month}
                </option>
              );
            })}
          </select>
        </div>
        <div className="control-container">
          <input
            type="checkbox"
            onChange={() => setShowHolidays(!showHolidays)}
          />
          <label>Show Holiday </label>
        </div>
      </div>
      <div className="classes-list-container ">
        {data &&
          data.classes.length > 0 &&
          data.classes.map((class_, index) => {
            if (!showHolidays && class_.scheduleType === "Holiday") {
              return null;
            }
            return (
              <ClassCard
                city={city}
                Class={class_}
                component={component}
                key={index}
                WeekNumber={class_.weekNumber}
              />
            );
          })}
        {(!data || data.classes.length === 0) && (
          <Loading2 loaded={data !== null} />
        )}
      </div>
    </div>
  );
};

export default CourseCalendar;
