import axios from "axios";
import dayjs from "dayjs";
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

import Alert from "../../components/Alert/Alarm.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Header/Header.jsx";
import Loading2 from "../../components/Loading/Loading2.jsx";

import CancelCourse from "./CancelCourse.jsx";
import "./Courses.scss";

const CoursesPage = ({ city, component }) => {
  const [courses, setCourses] = useState(null);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [cancelStatus, setCancelStatus] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("message");

  const closeConfirmationAlert = () => {
    setCancelStatus(false);
  };

  const showAlert = (type, message) => {
    setAlertStatus(true);
    setAlertType(type);
    setAlertMessage(message);
  };

  const getCourses = useCallback(async () => {
    try {
      let coursesData = await axios.get(`/courses/${city}`);
      setCourses(coursesData.data.data);
    } catch (err) {
      console.log(err);
    }
  }, [city]);
  useEffect(() => {
    getCourses();
  }, [getCourses]);
  return (
    <div>
      <Header city={city} component={component} />
      <div className="courses-container">
        <div className="page-title">
          <p>{city}</p> <i className="fas fa-chevron-right"></i>
          <p>Courses</p>
        </div>
        {cancelStatus && (
          <CancelCourse
            city={city}
            component={component}
            currentCourse={currentCourse}
            closeHandler={closeConfirmationAlert}
            showAlert={showAlert}
          />
        )}
        {alertStatus && <Alert type={alertType}> {alertMessage} </Alert>}
        <div className="table-responsive animate__animated animate__fadeIn">
          <table className="table" cellSpacing="0" cellPadding="0">
            <thead>
              <tr className="header-tr">
                <th scope="col">Course</th>
                <th scope="col" className="hide-city-col">
                  City
                </th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {courses &&
                courses.length > 0 &&
                courses.map((course, index) => {
                  return (
                    <tr key={index}>
                      <td>{course.intakeName}</td>
                      <td className="hide-city-col">{course.city}</td>
                      <td>
                        {dayjs(course.startDate).format("DD - MM - YYYY")}
                      </td>
                      <td>{dayjs(course.endDate).format("DD - MM - YYYY")}</td>
                      <td>
                        <Link to={`/${city}/editcourse/${course._id}`}>
                          <button className="btn-edit-course">
                            <i className="fas fa-pencil-alt"></i>
                          </button>
                        </Link>
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            setCurrentCourse(course);
                            setCancelStatus(true);
                          }}
                          className="btn-delete-course"
                        >
                          <i className="far fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {(!courses || courses.length === 0) && (
                <tr>
                  <td>
                    <Loading2 loaded={courses !== null} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CoursesPage;
