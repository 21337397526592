import axios from "axios";
import dayjs from "dayjs";
import React, { useContext, useEffect, useCallback, useState } from "react";
import { Link } from "react-router-dom";

import UserContext from "../../context/UserContext";
import { MonthNames } from "../../utils/MonthNames";
import Alert from "../Alert/Alarm.jsx";
import ClassVolunteersList from "../ClassVolunteersList/ClassVolunteersList.jsx";
import Loading2 from "../Loading/Loading2.jsx";
import NewBookingForm from "../NewBookingForm/NewBookingForm.jsx";

import CancelClass from "./CancelClass.jsx";
import EmailToAll from "./EmailToAll.jsx";
import "./ClassCard.scss";

const ClassCard = ({ city, component, Class, WeekNumber }) => {
  const [currentBookings, setCurrentBookings] = useState(null);
  const [cancelStatus, setCancelStatus] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("message");
  const [sendEmail, setSendEmail] = useState(false);

  const closeConfirmationAlert = () => {
    setCancelStatus(false);
  };

  const emailClose = () => {
    setSendEmail(false);
  };

  const showAlert = (type, message) => {
    setAlertStatus(true);
    setAlertType(type);
    setAlertMessage(message);
  };

  const get_booking = useCallback(async () => {
    await axios.get(`/bookings/${Class._id}`).then((response) => {
      setCurrentBookings(response.data.data);
    });
  }, [Class]);

  useEffect(() => {
    get_booking();
  }, [get_booking]);
  const user = useContext(UserContext);
  // console.log(currentBookings);
  return (
    <React.Fragment>
      {cancelStatus && (
        <CancelClass
          city={city}
          component={component}
          currentClass={Class}
          closeHandler={closeConfirmationAlert}
          showAlert={showAlert}
        />
      )}
      {alertStatus && <Alert type={alertType}> {alertMessage} </Alert>}
      {!Class ? (
        <Loading2 loaded={currentBookings !== null} />
      ) : (
        <div
          className={
            component === "coursecalendar"
              ? "classcard-body coursecalendar-class-size"
              : "classcard-body"
          }
        >
          <div className="classcard-main animate__animated animate__fadeIn">
            <div className="classcard-border">
              <div className="classcard-container">
                <div className="classcard-date-container">
                  <div className="classcard-date">
                    <p>{new Date(Class.date).getDate().toString()}</p>
                    <p>
                      {MonthNames[new Date(Class.date).getMonth().toString()]}
                    </p>
                    <p>{new Date(Class.date).getFullYear().toString()}</p>
                  </div>
                  {isNaN(WeekNumber) === false ? (
                    <div
                      className={
                        Class.scheduleType === "Holiday"
                          ? "weeknumber-container-sm holiday-week"
                          : "weeknumber-container-sm"
                      }
                    >
                      <p> Week {WeekNumber}</p>
                    </div>
                  ) : null}
                </div>
                <div className="classcard-info">
                  <div className="classcard-top">
                    <div>
                      <p className="classcard-title">{Class.className}</p>
                      <p>{Class.scheduleType}</p>
                      <p>
                        {Class.scheduleType !== "Holiday" &&
                          Class.startTime + " - " + Class.endTime}
                      </p>
                    </div>
                    {Class.scheduleType !== "Holiday" && (
                      <div className="URLs-container">
                        {Class.agendaURL && Class.agendaURL.length > 0 && (
                          <div>
                            <a
                              href={Class.agendaURL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="classcard-syllabus">
                                <p className="classcard-text-ctl">Agenda</p>
                                <i className="fas fa-calendar-alt"></i>
                              </div>
                            </a>
                          </div>
                        )}
                        <div>
                          <a
                            href={Class.syllabusURL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="classcard-syllabus">
                              <p className="classcard-text-ctl">Syllabus</p>
                              <i className="fas fa-book-open "></i>
                            </div>
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      currentBookings && currentBookings.length === 0
                        ? "classcard-volunteers-count-mobile-Disable"
                        : "classcard-volunteers-count-mobile"
                    }
                  >
                    <Link
                      onClick={(e) => {
                        if (currentBookings && currentBookings.length === 0) {
                          e.preventDefault();
                        }
                      }}
                      to={`/${city}/attendingvolunteers/${Class._id}/${WeekNumber}`}
                    >
                      {component !== "overview" &&
                      Class.scheduleType !== "Holiday"
                        ? currentBookings && currentBookings.length === 0
                          ? "no volunteer signed up"
                          : currentBookings && currentBookings.length
                          ? `${currentBookings.length} volunteer(s) signed up`
                          : null
                        : ""}
                    </Link>
                  </div>
                  <div
                    className={
                      currentBookings && currentBookings.length === 0
                        ? "classcard-disable-bottom"
                        : "classcard-bottom"
                    }
                  >
                    <Link
                      className="classcard-edit-Link"
                      onClick={(e) => {
                        if (currentBookings && currentBookings.length === 0) {
                          e.preventDefault();
                        }
                      }}
                      to={`/${city}/attendingvolunteers/${Class._id}/${WeekNumber}`}
                    >
                      <p>
                        {component !== "overview" &&
                        Class.scheduleType !== "Holiday"
                          ? currentBookings && currentBookings.length === 0
                            ? "no volunteer signed up"
                            : currentBookings && currentBookings.length
                            ? `${currentBookings.length} volunteer(s) signed up`
                            : null
                          : ""}
                      </p>
                    </Link>
                    {component !== "newbooking" && (
                      <div>
                        {user.role === "Administrator" && (
                          <React.Fragment>
                            {component === "attendingvolunteers" && (
                              <button
                                onClick={() => {
                                  setSendEmail(true);
                                }}
                                className="classcard-email-bottom"
                              >
                                <span className="classcard-text-ctl">
                                  Send Email
                                </span>
                                <i className="far fa-paper-plane classcard-icon-ctl"></i>
                              </button>
                            )}
                            <button
                              onClick={() => {
                                setCancelStatus(true);
                              }}
                              className="classcard-cancel-bottom"
                            >
                              <span className="classcard-text-ctl">Cancel</span>
                              <i className="fa fa-times classcard-icon-ctl"></i>
                            </button>
                            <Link
                              className="classcard-edit-bottom"
                              to={`/${city}/editclass/${Class._id}`}
                            >
                              <span className="classcard-text-ctl">Edit</span>
                              <i className="fa fa-pencil classcard-icon-ctl"></i>
                            </Link>
                          </React.Fragment>
                        )}
                        {["Administrator", "Volunteer"].includes(user.role) &&
                          Class.scheduleType !== "Holiday" && (
                            <Link
                              onClick={(e) => {
                                if (
                                  dayjs(Class.date).format("DD/MM/YYYY") <
                                  dayjs(new Date())
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              className={
                                dayjs(Class.date).format("DD/MM/YYYY") <
                                dayjs(new Date())
                                  ? "class-card-disable-attend-bottom"
                                  : "classcard-attend-bottom"
                              }
                              to={`/${city}/newbooking/${Class._id}/${WeekNumber}`}
                            >
                              <span className="classcard-text-ctl">
                                Choose The Role
                              </span>
                              <i className="fa fa-check-square-o classcard-icon-ctl"></i>
                            </Link>
                          )}
                      </div>
                    )}
                  </div>
                </div>
                {WeekNumber && (
                  <div
                    className={
                      Class.scheduleType !== "Holiday"
                        ? "weeknumber-container"
                        : "weeknumber-container holiday-week"
                    }
                  >
                    <p>Week</p>
                    <p>{WeekNumber}</p>
                  </div>
                )}
              </div>
              {sendEmail && (
                <EmailToAll Class={Class} emailClose={emailClose} />
              )}
              {!["upcomingclass", "coursecalendar", "overview"].includes(
                component
              ) && <hr className="classcard-separator"></hr>}
              {component === "newbooking" && (
                <NewBookingForm
                  user={user}
                  Class={Class}
                  city={city}
                  WeekNumber={WeekNumber}
                />
              )}
              {component === "attendingvolunteers" && (
                <ClassVolunteersList
                  city={city}
                  id={Class._id}
                  component={component}
                  bookings={currentBookings}
                  WeekNumber={WeekNumber}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ClassCard;
