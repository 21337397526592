import axios from "axios";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./EmailToAll.scss";

const EmailToAll = ({ Class, emailClose }) => {
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  const sendEmail = async (e) => {
    e.preventDefault();
    if (!subject || !body || body === "<p><br></p>") {
      alert("Please add your text to email!");
    } else {
      await axios.post("/bookings/email/send", {
        classId: Class._id,
        subject,
        emailText: body,
      });
      emailClose(false);
    }
  };

  return (
    <form className="email-body" onSubmit={sendEmail}>
      <div className="close-bt"></div>
      <div className="subject-email">
        <p>Send Email to all volunteers</p>
        <input
          className="subject"
          onChange={({ target: { value } }) => setSubject(value)}
          placeholder="Subject"
          required
          type="text"
          value={subject}
        />
        <ReactQuill
          onChange={(value) => setBody(value)}
          required
          theme="snow"
          value={body}
        />
      </div>
      <button type="submit" className="send">
        Send <i className="far fa-paper-plane"></i>
      </button>
      <button className="cancel" onClick={() => emailClose(false)}>
        Cancel <i className="fa fa-times classcard-icon-ctl"></i>
      </button>
    </form>
  );
};

export default EmailToAll;
