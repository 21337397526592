import { useState, useRef } from "react";
import validator from "validator";

export const useForm = (callback) => {
  const [error, setError] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const entryData = useRef([]);

  const validateForm = () => {
    let _error = {};
    entryData.current.forEach((element) => {
      console.log(element);
      let key = "";
      if (element.element) {
        switch (element.element.type) {
          case "text":
            if (element.required && element.element.value === "") {
              key = element.element.name;
              _error[key] = `${element.element.name} is required!`;
            }
            break;
          case "date":
            if (
              element.element.value !== "" &&
              !validator.isDate(element.element.value)
            ) {
              key = element.element.name;
              _error[key] = `${element.element.name} is not Valid!`;
            }
            if (element.required && element.element.value === "") {
              key = element.element.name;
              _error[key] = `${element.element.name} is required!`;
            }
            break;
          case "time":
            if (
              element.element.value !== "" &&
              !new RegExp(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]?$/g).test(
                element.element.value
              )
            ) {
              key = element.element.name;
              _error[key] = `${element.element.name} is not Valid!`;
            }
            if (element.required && element.element.value === "") {
              key = element.element.name;
              _error[key] = `${element.element.name} is required!`;
            }
            break;
          case "number":
            if (
              element.element.value !== "" &&
              !new RegExp(/^[0-9]*$/g).test(element.element.value)
            ) {
              key = element.element.name;
              _error[key] = `${element.element.name} is not Valid!`;
            }
            if (element.required && element.element.value === "") {
              key = element.element.name;
              _error[key] = `${element.element.name} is required!`;
            }
            break;
          case "email":
            if (
              element.element.value !== "" &&
              !validator.isEmail(element.element.value)
            ) {
              key = element.element.name;
              _error[key] = `${element.element.name} is not Valid!`;
            }
            if (element.required && element.element.value === "") {
              key = element.element.name;
              _error[key] = `${element.element.name} is required!`;
            }
            break;
          case "select-one":
            if (
              element.required &&
              (element.element.value === "" ||
                element.element.value === "Choose the role you want to fill")
            ) {
              key = element.element.name;
              _error[key] = `${element.element.name} is required!`;
            }
            break;
          case "url":
            if (
              element.element.value !== "" &&
              !validator.isURL(element.element.value)
            ) {
              key = element.element.name;
              _error[key] = `${element.element.name} is not Valid!`;
            }
            if (element.required && element.element.value === "") {
              key = element.element.name;
              _error[key] = `${element.element.name} is required!`;
            }
            break;
          default:
            break;
        }
      }
    });
    return _error;
  };
  const onChange = (event) => {
    // all changing elements must have a name
    // so ignore if no name
    console.log(event.target, event.target.value);
    if (event.target.name) {
      // mark the data as dirty
      setSubmitted(false);
      // now clear any error to do with this element
      const name = event.target.name;
      setError((error) => {
        delete error[name];
        return error;
      });
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (!submitted) {
      setSubmitted(true);
      const basicErrors = validateForm();
      if (JSON.stringify(basicErrors) !== "{}") {
        setError(basicErrors);
      } else {
        let _Values = {};
        let key = "";
        entryData.current.forEach((dataItem) => {
          const element = dataItem.element;
          if (element) {
            key = element.name;
            switch (element.type) {
              case "checkbox":
                _Values[key] = element.checked;
                break;
              case "radio":
                if (element.checked) _Values[key] = element.value;
                break;
              default:
                _Values[key] = `${element.value}`;
                break;
            }
          }
        });
        callback(_Values); // try to submit
        setError({});
      }
    }
  };

  const setUnsubmitted = () => {
    setSubmitted(false);
  };

  return {
    entryData,
    onChange,
    onSubmit,
    setUnsubmitted,
    error,
  };
};
